
import { Component, Vue } from '@/lib/decorator';

import TitleReturn from '@/components/TitleReturn.vue';
import Loading from '@/components/Loading.vue';

import periodsFinanceModule from '@/store/finance/periods';
import AppTable from '@/components/ui/table/Table.vue';
import TextMoney from '@/components/table-items/TextMoney.vue';
import TextDatetime from '@/components/table-items/TextDatetime.vue';
import TextNumber from '@/components/table-items/TextNumber.vue';
import FinanceModule from '@/store/finance';
import AppRow from '@/components/ui/grid/flex/Row.vue';
import AppCol from '@/components/ui/grid/flex/Col.vue';
import { TableApiInterface, TableRow } from '@/lib/layouts/page/table.interface';
import { minutesToTime, timeToMinutes } from '@/lib/Utils';

@Component({
  components: {
    AppCol,
    AppRow,
    TextNumber,
    TextDatetime,
    TextMoney,
    AppTable,
    TitleReturn,
    Loading,
  },
})
export default class FinancePeriodsView extends Vue {
  isLoading = true;

  get totalSum(): number {
    return (this.periodsInfoTable.rows as TableRow[]).reduce((sum: number, el: TableRow) => sum + el.sum, 0);
  }

  get employeeName(): string {
    return periodsFinanceModule.employeeName;
  }

  get periodsTitle(): string {
    return periodsFinanceModule.periodsTitle;
  }

  get datePeriods(): string[] {
    return Object.entries(this.$route.query)
      .filter(([key]) => key.includes('date'))
      .map(([, value]) => value) as string[];
  }

  get dateEnd(): string {
    return (this.$route.query['date-end'] as string) || '';
  }

  get partner(): string {
    return (this.$route.query['partner'] as string) || '';
  }

  get periodsDetailTable(): TableApiInterface {
    return periodsFinanceModule.periodsDetailTable;
  }

  get periodsInfoTable(): TableApiInterface {
    return periodsFinanceModule.periodsInfoTable;
  }

  get periodsTotal(): number {
    return periodsFinanceModule.periodsTotal;
  }

  get isTest(): boolean {
    return periodsFinanceModule.isTest;
  }

  getTimeToMinutes(hours: string): number {
    return timeToMinutes(hours);
  }

  getTime(minutes: number): string {
    return minutesToTime(minutes);
  }

  returnBefore(): void {
    this.$router.push({ name: 'finance_periods' });
  }

  async created(): Promise<void> {
    await periodsFinanceModule.getPeriodsTitle(this.datePeriods);

    const queryParameters: { employeeId: string; date: string; partner: string } = {
      employeeId: this.$route.params.id as string,
      date: this.dateEnd,
      partner: this.partner,
    };

    await periodsFinanceModule.getPeriodsDetail(queryParameters);
    this.isLoading = false;
  }

  mounted(): void {
    FinanceModule.initTabsItems();
  }
}
